import React from "react"

class DevMethod extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod mb-0 hdonly">
        <div className="container"> 
          <h3 className="heading-h2 text-center">
            <span className="bluecolor">Revenue Streams</span> Of OpenSea Clone Script</h3>
          <p className="text-center">
          Our OpenSea clone software helps entrepreneurs generate lucrative revenue in a short period.
          </p>
          <div className="d-lg-flex">
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/transaction-fees.png"
                  alt="Transaction fees"
                />
                <span>Transaction fees</span>
              </h4>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/registration-fees.png"
                  alt="Registration fees"
                />
                <span>Registration fees</span>
              </h4>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/premium-service-fee.png"
                  alt="Premium Service fees"
                />
                <span>Premium Service fee</span>
              </h4>
            </div>
          </div>
          <div className="d-lg-flex">
          <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/minting-fees.png"
                  alt="Minting fees"
                />
                <span>Minting fees</span>
              </h4>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/listing-fees.png"
                  alt="Listing fees"
                />
                <span>Listing fees</span>
              </h4>
            </div>
            <div className="square">
              <h4 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/auction-bidding-fee.png"
                  alt="Auction bidding fee"
                />
                <span>Auction bidding fee</span>
              </h4>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default DevMethod
