import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      <Container>

      <div className="text-center">
          
          <h6 className="heading-h2"><span className="heading-h3 bluecolor">FAQ </span> 
            Frequently Asked Questions</h6>
        </div>

        <Accordion defaultActiveKey={activeId}>

            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What is an OpenSea Clone Script?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">An OpenSea clone script is a ready-made software solution designed to replicate the features and functionalities of OpenSea, the leading NFT Marketplace. It's a cost-effective and efficient way to enter the NFT market.
                </div>
              </Accordion.Collapse>
            </div>
    
      
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What is the actual cost of an OpenSea Clone Script?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The estimated cost of an OpenSea clone software ranges between $8,000 to $15,000. This price can differ according to the requirements and customizations needed in the software.  
                </div>
              </Accordion.Collapse>
            </div>
         
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Does Coinsclone’s OpenSea Clone support multiple blockchains?
                </Accordion.Toggle>
              </div> 
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Yes! Our OpenSea clone script is compatible with different blockchain networks like Ethereum, Tron, Binance Smart Chain, etc. Multiple blockchain compatibility features are a major advantage of choosing our software solution.
                </div>
              </Accordion.Collapse>
            </div>
      
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How long does it take to launch an OpenSea-like NFT platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">With our White label OpenSea clone, you can launch an NFT Marketplace like OpenSea in just 7 to 10 days. However, for additional features to be included, the development time can slightly increase respectively.
                </div>
              </Accordion.Collapse>
            </div>
          
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Can this OpenSea Clone be customized to fit my business needs?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, Our OpenSea Clone Script is highly customizable. You can modify the design, user interface, features, and functionality to align with your brand identity and business goals.
                </div>
              </Accordion.Collapse>
            </div>
          
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                Is Your OpenSea Clone Script completely secure?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Our OpenSea clone script is built with security features like smart contract integration, 2FA, and encryption, ensuring secure NFT transactions and protection against potential threats.
                </div>
              </Accordion.Collapse>
            </div>
       
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle" variant="link" eventKey="6">
                Is it Legal to Use an OpenSea Clone Script?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Absolutely! It is generally legal to use an OpenSea clone script. However, it's important to consult with legal experts to ensure compliance with local regulations and intellectual property laws. Additionally, avoid infringing on OpenSea's trademarks or copyrights.
                </div>
              </Accordion.Collapse>
            </div>
      
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection