import React from 'react'



const VariousBlock =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h3 className="heading-h2 text-center">Our OpenSea Clone Script Supports <span className="bluecolor">Various Blockchains</span></h3>
          <p className="text-center">Our OpenSea-like NFT marketplace can be integrated with the most popular blockchain networks. Our team of expert developers has ensured that the software we offer is compatible with multiple blockchains in the crypto industry.</p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/tron-token.png" alt="Tron" /><span>Tron</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana" /><span>Solana</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon" /><span>Polygon</span></p>
            </div>
            <div className="revenue">
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/wave.png" alt="Waves" /><span>Waves</span></p>
            <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/arbritum.png" alt="Arbritum" /><span>Arbritum</span></p>
              
            </div>
          </div>
        </div>
      </section>
    )
}

export default VariousBlock