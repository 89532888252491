import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="bluecolor">What is</span> OpenSea Clone Script?</h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph">OpenSea Clone Script is a pre-designed software solution that enables you to create an NFT marketplace with the essential features and functionalities of OpenSea. This customizable clone script is expertly tested and optimized for immediate deployment, enabling startups to create a captivating NFT Marketplace like OpenSea. With advanced technologies, powerful add-ons & APIs, you can launch an OpenSea-like NFT Marketplace and leverage the benefits of a scalable product while minimizing development time and costs.
              </p>
              <p className="pharagraph mb-0">Coinsclone's OpenSea Clone Script is a pre-assembled and fine-tuned NFT marketplace script that can be deployed instantly, allowing users to trade NFTs across various blockchain networks. By using our white-label OpenSea clone software, you can seamlessly attract a large user base of NFT enthusiasts to your platform, unlocking numerous revenue opportunities. More precisely, our best-in-class OpenSea clone enables you to quickly establish your digital presence and dominate the NFT market.</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis