import { StaticImage } from 'gatsby-plugin-image';
import React,{useEffect,useState} from 'react'

const WhyShould =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);


    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Why Create</span> an NFT Marketplace like OpenSea?
              </h2>
              <p className='text-center marbot-0'>
              According to statistics, over 80% of NFT transactions occur on OpenSea Marketplace, making it a dominant player in the NFT ecosystem. For startups and investors, creating an NFT marketplace similar to OpenSea is a strategic move to enter the lucrative NFT market.
              </p>
          <div className="row table-content">
            {isDesktop && (
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/why-should-you-create-nft-marketplace-using-opensea-clone-script.png"
                      alt="Create an NFT Marketplace using our OpenSea Clone Script"
                      width={625}
                    />
            </div>
            )}
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"><b>User-Friendly Interface -</b> OpenSea's intuitive interface simplifies the trading process, making it more accessible.
                </li>
                <li className="pharagraph"><b>Diverse Asset Support -</b> It supports a wide range of NFTs, including art, music, gaming items, virtual land, and domain names. 
                </li>
                <li className="pharagraph"><b>Secured Smart Contracts -</b> OpenSea utilizes robust smart contracts, ensuring secure transactions of NFTs.
                </li>
                <li className="pharagraph"><b>Interoperability -</b> It integrates multiple blockchain networks like Ethereum (ETH), Polygon (MATIC), and Klaytn, enhancing flexibility.
                </li>
                <li className="pharagraph"><b>Revenue Potential -</b> OpenSea generates revenue through transaction fees, providing a sustainable business model.
                </li>
                <li className="pharagraph"><b>Strong Community -</b> OpenSea has built a loyal customer base, highlighting the effectiveness of its user-focused approach.</li>
              </ul>
            </div>
          </div>
          <div className='text-center mt-2 mt-md-4'>
          <a href="/contact-us/" className="bluebtn">Free Trial</a>
          </div>
        </div>
      </section>
    )
  }


export default WhyShould;