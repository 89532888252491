import React,{useState,useEffect} from "react";
import { StaticImage } from "gatsby-plugin-image";

const CoreFeatures = () => {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);

  // Tab
  const openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  };

  return (
    <section className="trading bgremove pt-100 mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2">
              <span className="bluecolor">Add-On Features</span> Of Our OpenSea Clone Script
          </h2>
        </div>
        <div className="row">
          <div className="courses-details-desc">
            <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
              <li
                className="current"
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab1")}
              >
                NFT Wallet Integration
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab2")}
              >
                Cross-chain Compatibility
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab7")}
              >
                Royalties
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab8")}
              >
                Token Search and filter
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab9")}
              >
                Virtual NFT options
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab10")}
              >
                Payment Gateway 
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab11")}
              >
                Escrow Based Trade
              </li>
              <li
                role="presentation"
                onKeyDown={openTabSection}
                onClick={(e) => openTabSection(e, "tab12")}
              >
                API Documentation
              </li>
            </ul>

            <div className="tab-content">
              <div id="tab1" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">NFT Wallet Integration</h3>
                      <p className="pharagraph">
                      Our NFT Marketplace like OpenSea allows integration of any popular crypto wallets for NFT storing and accessing.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea/crypto-wallets.png"
                      alt="NFT Wallet Integration"
                      width={380}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab2" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Cross-chain Compatibility</h3>
                      <p className="pharagraph">
                      The script is compatible with multiple blockchain networks such as Ethereum, Binance Smart Chain, Polygon, etc.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/multi-chain-support.png"
                      alt="Cross-chain Compatibility"
                      width={329}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab7" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Royalties</h3>
                      <p className="pharagraph">
                      The royalties feature facilitates creators to earn a small percentage of trading profit whenever their NFTs get resold.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/trending-collections.png"
                      alt="Royalties"
                      width={350}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab8" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Token Search and filter</h3>
                      <p className="pharagraph">
                      We exhibit an ultimate token search option to help your users identify, explore, and filter their desired NFTs without much effort.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/filter.png"
                      alt="Token Search and filter"
                      width={350}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab9" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Virtual NFT options</h3>
                      <p className="pharagraph">
                      We ensure that our NFT Website has the capabilities to tokenize virtual lands and real-world collectibles to Metaverse.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/virtual-nft-options.webp"
                      alt="Virtual NFT options"
                      width={300}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab10" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Payment Gateway</h3>
                      <p className="pharagraph">
                      We provide the facility to integrate multiple payment options including credit/debit cards, bank wire, and payment gateways.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/payment-gateway.png"
                      alt="Payment Gateway"
                      width={380}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab11" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">Escrow Based Trade</h3>
                      <p className="pharagraph">
                      The digital collectibles in the platform are well protected by the escrow system which is especially integrated to prevent fraudulent activities.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea/escrow-based-trade.png"
                      alt="Escrow Based Trade"
                      width={350}
                    />
                  </div>
                  )}
                </div>
              </div>
              <div id="tab12" className="tab-pane tabs_items">
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h3 className="heading-h4">API Documentation</h3>
                      <p className="pharagraph">
                      Our solution can provide a rest API for fetching NFT items based on parameters that include sales, bids, transfers, and more.
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/api-documentation.png"
                      alt="API Documentation"
                      width={400}
                    />
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreFeatures;
