import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
          <h4 className="heading-h2 text-center"><span className='bluecolor heading-h3'>How Does</span> Our OpenSea Clone Script Work?</h4>
          { isDesktop && (
          <StaticImage
          src="https://coinsclone.mo.cloudinary.net/images/opensea-new/workflow-opensea.png"
          alt="Working Process of our Opensea clone software Image"
          width={1000}
        />
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/opensea-mobile.webp" 
              alt="opensea-clone-script-workflow"
              width={392} />
              )}
        
      </div>
    </section>
    </div>
  )
}

export default HowOur