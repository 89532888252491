
import React from 'react'



const WhatsNew  =()=> {




    return (
        <div className='cryptoex'>
      <section className="pt-100 secureof">
         <div className='gray-bg'>
            <div className="container">
            <h2 className="heading-h2 text-center">Whats <span className='bluecolor'>new?</span></h2>
            <div className="row ">
                <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
                    <div class="custom-card">
                            <h3 className='text-left text-md-center'>White Label Opensea Clone Development</h3>
                            <p className='pharagraph text-left text-md-center'>Coinsclone’s White Label OpenSea Clone enables you to launch your own NFT Marketplace with ease. Our NFT Marketplace solution offers a bug-free decentralized P2P platform that mirrors OpenSea’s features, allowing you to cater to your unique business needs. With advanced Web3 integration and a user-friendly interface, our clone is designed to deliver a seamless NFT trading experience. Benefit from our cost-effective development process and quick deployment to efficiently establish your exclusive marketplace. </p>
                    </div>
                    <div class="custom-card">
                            <h3 className='text-left text-md-center'>Opensea Clone App Development</h3>
                            <p className='pharagraph text-left text-md-center'>An OpenSea Clone App is a replica of the OpenSea NFT Marketplace. As mobile NFT trading grows, our OpenSea clone app development service offers NFT Trading Apps compatible with Android and iOS. Also, Our OpenSea clone app operates on the reputable Ethereum blockchain network, and its framework is designed using the latest technology stacks. Launch a high-quality NFT Marketplace app with Coinsclone to quickly attract a global audience of traders and creators.</p>
                    </div>
                </div>
            </div>
            </div>
         </div>
      </section>
      </div>
    )
  }


export default WhatsNew